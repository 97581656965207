










import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component
export default class Unauthorized extends Vue {
  public get signInURL() {
    const redirect =
      this.$route.name === 'unauthorized' &&
      this.$route.query &&
      (this.$route.query.redirect as string);
    const state = redirect || location.pathname + location.search;
    return this.$auth.createURL(state);
  }
}
